/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { array, func } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import CloseCircleIcon from '../../../BrandTheme/Icons/CloseCircleIcon';
import AddCircleIcon from '../../../BrandTheme/Icons/AddCircleIcon';
import { getBrand } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles((theme) => ({
    base: {
        borderBottom: '1px solid #E9E9E9',
    },
    title: {
        fontSize: '14px',
        fontWeight: 700,
        padding: '12px 10px',
        [theme.breakpoints.down(480)]: {
            padding: '12px 4px',
            fontSize: '12px',
        },
    },
    chipItemBase: {
        width: '88%',
        [theme.breakpoints.down(1024)]: {
            width: '92%',
        },
        [theme.breakpoints.down(720)]: {
            width: '90%',
        },
        [theme.breakpoints.down(480)]: {
            width: '80%',
        },
    },
    chipsContainer: {
        overflowX: 'auto',
        overflowY: 'hidden',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '2px',
        padding: '8px 0px',
        backgroundColor: 'white',
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: 'rgba(156, 156, 156, 1)',  // scroll bar color
        },
        '&::-webkit-scrollbar': {  /* Works on scrollbar for Chrome, Safari and Opera */
            height: '3px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: 'transparent',
        },
    },
    chip: {
        display: 'flex',
        backgroundColor: '#F5F5F5',
        padding: '8px 12px',
        borderRadius: '30px',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        '& svg': {
            cursor: 'pointer',
            height: '18px',
            width: '18px',
        },
        [theme.breakpoints.down(720)]: {
            fontSize: '14px',
        },
    },
    allBrandChip: {
        cursor: 'pointer',
    },
    activeChip: {
        backgroundColor: '#EFF9F6',
    },
    chipBtn: {
        border: 'none',
        padding: 0,
        backgroundColor: 'transparent',
        lineHeight: '8px',
        margin: '0px 0px 0px 4px',
    },
}));

const BRANDS = [{
    name: '1-800-Flowers',
    value: '1001',
}, {
    name: 'Harry & David',
    value: '1019',
}, {
    name: 'Personalization Mall',
    value: '1030',
}, {
    name: 'Shari\'s Berries',
    value: '1029',
}, {
    name: '1-800-Baskets',
    value: '1009',
}, {
    name: 'Simply Chocolate',
    value: '1025',
}, {
    name: 'Fruit Bouquets',
    value: '1010',
}, {
    name: 'Cheryl\'s Cookies',
    value: '1012',
}, {
    name: 'The Popcorn Factory',
    value: '1002',
}, {
    name: 'Wolferman\'s Bakery',
    value: '1020',
}, {
    name: 'Vital Choice',
    value: '1031',
}];

const SearchBrandFilter = ({ selectedBrands, setSelectedBrands }) => {
    const classes = useStyles();
    const containerRef = useRef(null);
    const currentBrand = useSelector(getBrand);

    // Move the selected first, current brand next and remaining.
    const onSiteFilters = [
        ...BRANDS.filter((item) => selectedBrands.includes(item.value))
            .sort((a, b) => selectedBrands.indexOf(a.value) - selectedBrands.indexOf(b.value)),
        ...BRANDS.filter((item) => item.value === currentBrand.id && !selectedBrands.includes(item.value)),
        ...BRANDS.filter((item) => !selectedBrands.includes(item.value) && item.value !== currentBrand.id),
    ];

    // to handle scroll on brand list
    useEffect(() => {
        const container = containerRef.current;
        const handleScroll = (event) => {
            if (container) {
                event.preventDefault();
                container.scrollLeft += event.deltaX || event.deltaY;
            }
        };
        container.addEventListener('wheel', handleScroll, { passive: false });
        return () => container.removeEventListener('wheel', handleScroll);
    }, []);

    const handleBrandFilter = (value) => {
        const tempValue = [...selectedBrands];
        const tempValueIndex = tempValue.findIndex((a) => a === value);
        if (tempValueIndex > -1) {
            tempValue.splice(tempValueIndex, 1);
        } else {
            tempValue.push(value);
        }
        setSelectedBrands(tempValue);
    };

    return (
        <Grid data-testid="on-site-brand-search" container wrap="nowrap" alignItems="center" className={classes.base}>
            <Grid item className={classes.title}>Filter By:</Grid>
            <Grid item className={classes.chipItemBase}>
                <div className={classes.chipsContainer} ref={containerRef}>
                    <span
                        className={`${classes.chip} ${classes.allBrandChip} ${selectedBrands.length === 0 ? classes.activeChip : ''}`}
                        tabIndex="0"
                        role="button"
                        onClick={() => setSelectedBrands([])}
                        onKeyDown={() => setSelectedBrands([])}
                    >
                        All Brands
                    </span>
                    {onSiteFilters.map((obj) => (
                        <span className={`${classes.chip} ${selectedBrands.includes(obj.value) ? classes.activeChip : ''}`}>
                            {obj.name}
                            {selectedBrands.includes(obj.value)
                                ? (
                                    <button data-testid={`remove-onsite-brand-${obj.value}`} className={classes.chipBtn} type="button" onClick={() => handleBrandFilter(obj.value)}>
                                        <CloseCircleIcon />
                                    </button>
                                )
                                : (
                                    <button data-testid={`add-onsite-brand-${obj.value}`} className={classes.chipBtn} type="button" onClick={() => handleBrandFilter(obj.value)}>
                                        <AddCircleIcon />
                                    </button>
                                )}
                        </span>
                    ))}
                </div>
            </Grid>
        </Grid>
    );
};

SearchBrandFilter.propTypes = {
    selectedBrands: array.isRequired,
    setSelectedBrands: func.isRequired,
};

export default SearchBrandFilter;
